@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

html,
body,
#root {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  font-family: 'Roboto Mono', monospace;
}

body {
  background-color: skyblue;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

* {
  color: black;
  pointer-events: all;
  text-shadow: 0 0 5px #fff,0 0 10px #fff,0 0 15px #fff;
}

::selection {
  color: #eee;
  background: #333;
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 0.5s forwards;
}
